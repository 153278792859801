<template>
  <el-input v-model="internalValue" type="number" step="0.01" v-bind="$attrs" v-on="filteredListeners">
    <span v-if="showIcon" slot="prefix" class="px-2">{{ currencySymbol }}</span>
  </el-input>
</template>

<script type="text/javascript">
import { useCurrency } from '@/modules/document/compositions';

export default {
  props: {
    showIcon: { type: Boolean, default: true },
    value: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    const { currencySymbol } = useCurrency();

    return {
      currencySymbol,
    };
  },
  data() {
    return {
      internalValue: '',
    };
  },
  computed: {
    filteredListeners() {
      const filteredListeners = {};
      Object.keys(this.$listeners)
        .filter((key) => key !== 'input')
        .forEach((key) => (filteredListeners[key] = this.$listeners[key]));
      return filteredListeners;
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value === undefined) {
          this.internalValue = '';
          return;
        }
        if (!this.internalValue || Number(this.internalValue) !== value) {
          this.internalValue = value.toString();
        }
      },
      immediate: true,
    },
    internalValue(value) {
      if (!value) {
        this.$emit('input', undefined);
        return;
      }

      const moneyValue = Number(value);
      if (moneyValue !== this.value) {
        this.$emit('input', moneyValue);
      }
    },
  },
};
</script>
