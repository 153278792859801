<template>
  <div>
    <pdf-viewer ref="viewer" :src="docSrc" :page.sync="currentPage" :is-replicate-document="isReplicateDocument">
      <template slot="page" slot-scope="{ component, data }">
        <tippy :key="`tippy-${mode}`" to="overlay" follow-cursor :visible="!!highlightText" :duration="0">
          {{ highlightText }}
        </tippy>
        <drawing-overlay2
          v-if="mode === 'default'"
          ref="overlay"
          name="overlay"
          :selected-rects="selectedRects"
          @cursormove="onCursormove"
          @selectionStart="onSelectionStart"
          @selectionEnd="onSelectionEnd"
          @contextmenu="onContextMenu"
        >
          <component :is="component" :page="data" />
        </drawing-overlay2>
        <drawing-overlay
          v-else
          ref="overlay"
          name="overlay"
          mark-enabled
          @cursormove="onCursormove"
          @markStart="onMarkStart"
          @markEnd="onMarkEnd"
          @clickShape="onClickShape"
        >
          <component :is="component" :page="data" />
        </drawing-overlay>
      </template>

      <template slot="headerContext">
        <span v-if="fileName">{{ fileName }}</span>
        <el-tooltip
          v-if="isReplicateDocument"
          class="box-item fontSize"
          effect="dark"
          :content="$t('document.validation.documentNumber.replicatedDocument')"
          placement="top"
        >
          <DocumentDuplicate class="duplicate-document-icon" />
        </el-tooltip>
      </template>
    </pdf-viewer>
    <el-dialog v-if="dataMode" :visible.sync="dialogVisible" title="Configure Field" @close="onDialogClose">
      <el-select v-model="dialog.field">
        <el-option v-for="field in dataFields" :key="field" :value="field" />
      </el-select>
      <el-button v-if="subFields(dialog.field)" @click="dialog.columns.push({ field: '' })"> add column </el-button>
      <div v-if="subFields(dialog.field)" class="row">
        <div v-for="(col, index) in dialog.columns" :key="index" class="col-2 mt-2">
          <el-select v-model="col.field" clearable>
            <el-option v-for="subField in subFields(dialog.field)" :key="subField" :value="subField" />
          </el-select>
          <i class="el-icon-remove" @click="dialog.columns.splice(index, 1)" />
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="updateBoxBinding">update</el-button>
        <el-button type="danger" plain @click="removeBox">remove</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import { Document, Block } from '@clarityo/ocr-reader';
import { PdfViewer } from '@/modules/core/components/PdfViewer';

import { DocumentDuplicate } from '@/assets/icons';

import DrawingOverlay from './drawing_overlay.vue';
import DrawingOverlay2 from './drawing_overlay2.vue';
import Rect from '../utils/rect';

export default {
  components: { PdfViewer, DrawingOverlay, DrawingOverlay2, DocumentDuplicate },
  props: {
    isReplicateDocument: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileName: {
      type: String,
      required: false,
      default: '',
    },
    docSrc: {
      type: [String, ArrayBuffer],
      required: true,
    },
    ocrDoc: Document,
    mode: {
      type: String,
      default: 'magicClick',
    },
    dataFields: { type: Array, default: () => [] },
    tableFields: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentPage: 1,
      metaKeyPressed: false,
      dialogVisible: false,
      highlightText: '',
      dialog: {
        field: '',
        columns: [],
      },
      selectedBlocks2: [],
      tempSelectedBlocks: [],
    };
  },
  computed: {
    dataMode() {
      return this.mode === 'dataExtraction';
    },
    ocrPage() {
      if (!this.ocrDoc) return;
      return this.ocrDoc.pages[this.currentPage - 1];
    },
    selectedRects() {
      const page = this.ocrPage;
      const blocks = [...this.tempSelectedBlocks, ...this.selectedBlocks2];
      return blocks.map((block) =>
        Rect.createFromCoordinates(
          { x: block.l / page.width, y: block.t / page.height },
          { x: block.r / page.width, y: block.b / page.height }
        )
      );
    },
  },
  watch: {
    metaKeyPressed(value) {
      if (!value && this.selectedBlocks.length) {
        this.$emit('blocksSelection', this.selectedBlocks);
        this.selectedBlocks = [];
      }
    },
    dataMode(value) {
      if (value) {
        this.bindings.forEach((b) => {
          const oldShape = b.shape;
          b.shape = this.$refs.overlay.createBox(...oldShape.getRatioPoints());
          b.shape.setColumns(oldShape._columns);
        });
      } else {
        this.bindings.forEach((b) => {
          this.$refs.overlay.removeBox(b.shape);
        });
      }
    },
  },
  created() {
    this.startPoint = null;
    this.selectedBlocks = [];
    this.startCoordinate = null;
    this.currentShape = null;
    this.bindings = [];
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyEvent);
    window.addEventListener('keyup', this.onKeyEvent);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyEvent);
    window.removeEventListener('keyup', this.onKeyEvent);
  },
  methods: {
    onKeyEvent(e) {
      if (e.key === 'Meta') {
        this.metaKeyPressed = e.type === 'keydown';
      }
    },
    onCursormove(point) {
      if (this.dataMode) {
        const binding = this.bindings.find((b) => {
          const [topLeft, bottomRight] = b.shape.getRatioPoints();
          return point.x >= topLeft.x && point.x <= bottomRight.x && point.y >= topLeft.y && point.y <= bottomRight.y;
        });
        if (!binding) {
          this.highlightText = '';
          return;
        }
        const [topLeft, bottomRight] = binding.shape.getRatioPoints();
        if (binding.columns && binding.columns.length > 1) {
          const colSeps = binding.shape.getRatioColumns();
          const columnIndex = binding.columns.findIndex((col, index) => {
            const leftSep = colSeps[colSeps.length - index - 1] || topLeft.x;
            const rightSep = colSeps[colSeps.length - index] || bottomRight.x;
            return point.x > leftSep && point.x < rightSep;
          });
          if (columnIndex === -1) {
            this.highlightText = binding.field;
            return;
          }
          const leftSep = colSeps[colSeps.length - columnIndex - 1] || topLeft.x;
          const rightSep = colSeps[colSeps.length - columnIndex] || bottomRight.x;
          const value = this.findBlocks({ x: leftSep, y: topLeft.y }, { x: rightSep, y: bottomRight.y })
            .map((b) => b.text)
            .join('<br>');
          this.highlightText = `<strong>${binding.field}.${binding.columns[columnIndex].field}</strong><br>${value}`;
        } else {
          const value = this.findBlocks(topLeft, bottomRight)
            .map((b) => b.text)
            .join('<br>');
          this.highlightText = `<strong>${binding.field}</strong><br>${value}`;
        }
      } else {
        const currentBlocks = this.findBlocks(this.startPoint || point, point);
        this.highlightBlocks(currentBlocks.concat(this.selectedBlocks));
        if (!this.startPoint) {
          this.highlightText = currentBlocks.map((b) => b.text).join('\n');
        }
      }
      if (this.mode === 'default' && this.startCoordinate) {
        this.selectedBlocks2 = this.findBlocks(this.startCoordinate, point);
      }
    },
    onMarkStart(point) {
      this.startPoint = point;
    },
    onMarkEnd(point) {
      if (this.dataMode) {
        const shape = this.$refs.overlay.createBox(this.startPoint, point);
        this.bindings.push({ shape });
        this.currentShape = shape;
        this.dialogVisible = true;
      } else {
        // magick click mode
        const blocks = this.findBlocks(this.startPoint, point);
        if (this.metaKeyPressed) {
          this.selectedBlocks.push(...blocks);
          this.highlightBlocks(this.selectedBlocks);
        } else this.$emit('blocksSelection', blocks);
      }
      this.startPoint = null;
    },
    onClickShape(shape) {
      this.currentShape = shape;
      const binding = this.bindings.find((b) => b.shape === shape);
      if (binding) {
        this.dialog.field = binding.field;
        this.dialog.columns = binding.columns;
      }
      this.dialogVisible = true;
    },
    updateBoxBinding() {
      const field = this.dialog.field;
      if (!field) {
        this.$message.error('must define field');
        return;
      }
      const columns = this.isTableField(field) ? this.dialog.columns : [];
      const binding = this.bindings.find((b) => b.shape === this.currentShape);
      Object.assign(binding, { field, columns });

      if (columns.length !== this.currentShape.getRatioColumns().length + 1) {
        const colSep = [];
        if (columns.length > 1) {
          for (let i = 1; i < columns.length; i++) {
            colSep.push(i / columns.length);
          }
        }
        this.currentShape.setColumns(colSep);
      }
      this.dialogVisible = false;
    },
    getFieldBlocks() {
      return this.bindings.map((binding) => {
        const [topLeft, bottomRight] = binding.shape.getRatioPoints();
        const { width, height } = this.ocrPage;
        const block = new Block({
          t: topLeft.y * height,
          b: bottomRight.y * height,
          l: topLeft.x * width,
          r: bottomRight.x * width,
        });

        const fieldBlock = {
          field: binding.field,
          block,
        };

        if (binding.columns && binding.columns.length > 1) {
          const colSeps = binding.shape.getRatioColumns();
          fieldBlock.columnFields = binding.columns.map((colField, index) => {
            // RTL so going in reverse
            const leftSep = colSeps[colSeps.length - index - 1];
            const rightSep = colSeps[colSeps.length - index];
            const colBlock = new Block({
              t: block.t,
              b: block.b,
              l: leftSep ? leftSep * width : block.l,
              r: rightSep ? rightSep * width : block.r,
            });
            return {
              field: colField.field,
              block: colBlock,
            };
          });
        }

        return fieldBlock;
      });
    },
    setFieldBlocks(fieldBlocks) {
      this.bindings.forEach((binding) => {
        this.$refs.overlay.removeBox(binding.shape);
      });

      this.bindings = fieldBlocks.map((fieldBlock) => {
        const block = fieldBlock.block;
        const columns = [...(fieldBlock.columnFields || [])].reverse();
        const { width, height } = this.ocrPage;
        const start = { x: block.l / width, y: block.t / height };
        const end = { x: block.r / width, y: block.b / height };
        const colSeps = columns.map((col) => (col.block.r - block.l) / block.width);
        colSeps.pop();
        const shape = this.$refs.overlay.createBox(start, end);
        shape.setColumns(colSeps);
        return {
          field: fieldBlock.field,
          columns: fieldBlock.columnFields && fieldBlock.columnFields.map((colField) => ({ field: colField.field })),
          shape,
        };
      });
    },
    removeBox() {
      const bIndex = this.bindings.findIndex((b) => b.shape === this.currentShape);
      if (bIndex !== -1) this.bindings.splice(bIndex, 1);
      this.$refs.overlay.removeBox(this.currentShape);
      this.dialogVisible = false;
    },
    onDialogClose() {
      this.currentShape = null;
      this.dialog.field = '';
      this.dialog.columns = [];
    },
    findBlocks(startPoint, endPoint) {
      if (!this.ocrPage) return [];

      const page = this.ocrPage;
      const rect = new Block({
        t: Math.min(startPoint.y, endPoint.y) * page.height,
        b: Math.max(startPoint.y, endPoint.y) * page.height,
        l: Math.min(startPoint.x, endPoint.x) * page.width,
        r: Math.max(startPoint.x, endPoint.x) * page.width,
      });
      return page.textBlocks
        .filter((block) => block.isSameRow(rect) && block.isSameColumn(rect))
        .reduce((blocks, block) => blocks.concat(block.split(' ')), [])
        .filter((block) => block.isSameRow(rect) && block.isSameColumn(rect))
        .reduce((blocks, block) => {
          const previousBlock = blocks.pop();
          if (previousBlock && previousBlock.isSameRow(block)) {
            blocks.push(Document.joinTextBlocks([previousBlock, block], ' '));
          } else {
            if (previousBlock) blocks.push(previousBlock);
            blocks.push(block);
          }
          return blocks;
        }, []);
    },
    highlightBlocks(blocks) {
      const page = this.ocrPage;
      blocks.forEach((block) => {
        const start = { x: block.l / page.width, y: block.t / page.height };
        const end = { x: block.r / page.width, y: block.b / page.height };
        this.$refs.overlay.drawRect({ start, end });
      });
    },
    isTableField(field) {
      if (!field) return false;
      return this.tableFields[field];
    },
    subFields(field) {
      return this.tableFields[field];
    },
    onSelectionStart(coordinate) {
      this.startCoordinate = coordinate;
    },
    onSelectionEnd(rect) {
      this.startCoordinate = null;
      this.selectedBlocks2 = this.findBlocks(rect.topLeft, rect.bottomRight);
    },
    onContextMenu(event, coordinate) {
      if (!this.selectedRects.some((rect) => rect.includes(coordinate))) {
        this.selectedBlocks2 = this.findBlocks(coordinate, coordinate);
      }
      if (this.selectedBlocks2.length === 0) return;

      event.preventDefault();

      const data = [...this.selectedBlocks2]
        .sort(Block.compareVertically)
        .map((b) => b.text)
        .join('\n');
      this.$contextmenu({
        event,
        items: [
          {
            label: 'copy',
            onClick: () => {
              window.navigator.clipboard.writeText(data);
            },
          },
          {
            label: 'copy to',
            children: [
              {
                label: 'last focused field',
                onClick: () => this.$emit('copyTo', '', data),
              },
              {
                label: 'document number',
                onClick: () => this.$emit('copyTo', 'documentNumber', data),
              },
            ],
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.duplicate-document-icon {
  margin: 8px;
}
</style>
