<template>
  <el-dialog visible top="33vh" custom-class="rounded" :show-close="false" width="400px">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-0">
        <h2>{{ $t('document.FileUploadSource.header.title') }}</h2>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>

    <div class="p-3 file-preview">
      <div class="d-flex align-items-center h-100 pr-16">
        <DocumentsIcon style="padding: 2px" width="20px" height="20px" />
        <div style="width: 100%" class="pe-2">{{ filename }}</div>
      </div>
    </div>

    <h3 class="pt-5 modal-text-body">{{ $t('document.FileUploadSource.body.main') }}</h3>
    <p class="pb-3">{{ $t('document.FileUploadSource.body.paragraph') }}</p>

    <el-form ref="form" :model="model">
      <el-form-item
        prop="selectedSource"
        :rules="[{ required: true, message: $t('document.FileUploadSource.error.source') }]"
      >
        <el-radio-group v-model="model.selectedSource">
          <el-radio label="folder" size="large">{{ $t('document.FileUploadSource.body.folder') }}</el-radio>
          <el-radio label="email" size="large">{{ $t('document.FileUploadSource.body.email') }}</el-radio>
        </el-radio-group>
        <el-input
          v-model="model.fromEmail"
          size="medium"
          :placeholder="$t('document.FileUploadSource.body.emailExample')"
        />
        <p class="pb-3">{{ $t('document.FileUploadSource.body.emailExplanation') }}</p>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <Button size="medium" type="secondary" @click="$emit('close')">
        {{ $t('document.FileUploadSource.footer.close') }}
      </Button>
      <Button size="medium" @click="handleSelectedSource">
        {{ $t('document.FileUploadSource.footer.save') }}
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon, DocumentsIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon, DocumentsIcon },
  props: {
    filename: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        selectedSource: '',
        fromEmail: null,
      },
    };
  },
  methods: {
    handleSelectedSource() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit('selectedSource', this.model.selectedSource, this.model.fromEmail);
        this.$emit('close');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bold-radio {
  font-weight: bold;
}

.file-preview {
  width: 100%;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  background-color: #fbfbfb;
  color: #131416;
}
::v-deep .el-dialog__body {
  padding: 20px 20px;
}
.modal-text-body {
  font-size: 15px;
  font-weight: 500;
  color: #131416;
}
</style>
